import React, { useEffect } from 'react';
import Layout from 'components/system/layouts/Layout';
import { Grid, makeStyles } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { useAppDispatch } from 'redux/hooks';
import COMMON from 'constants/common';
import { getUserAuthThunk } from 'redux/slices/commonSlice';
import PrevNextButton from 'components/system/atoms/buttons/PrevNextButton';
import { animateScroll as scroll } from 'react-scroll';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import VoiceListTable from '../../components/machineConf/organisms/tables/VoiceListTable';
import { VoiceType } from '../../types/voice/voiceType';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'ボイス一覧',
  screenId: 'SCR811',
  currentMenu: COMMON.MENU.VOICE_LIST,
  initializeState: true,
};
const TABLE_ID = 'dataVoiceListTable';
const MAX_DISPLAY_BAR = 7;
/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  contents: {
    padding: '3px 16px 24px 16px',
  },
  selectBoxWrap: { marginRight: '12px' },
  description: {
    padding: '0 0 0px 24px',
    height: '36px',
    fontSize: '23px',
    color: '#0077FF',
  },
  descriptionContent: {
    marginBottom: 50,
  },
  sortArea: {
    paddingBottom: '24px',
  },
  decision: {
    textAlign: 'right',
    width: '1100px',
    marginTop: '-20px',
  },
  previousBtn: {
    paddingBottom: '10px',
    position: 'inherit',
    textAlign: 'center',
    width: '100%',
    height: '29px',
    marginTop: '-20px',
  },
  nextBtn: {
    paddingTop: '5px',
    textAlign: 'center',
    height: '29px',
  },
  selectBoxOrder: {
    marginTop: '10px',
  },
}));

const VoiceList: React.FC = () => {
  /* ************ hooks ************ */
  const classes = useStyles();
  const [voiceList, setVoiceList] = React.useState<Array<VoiceType>>([]);

  /* ************ state/redux ************ */
  const dispatch = useAppDispatch();

  /* ************ Event ************ */

  // get voice api
  const getVoiceListApi = async () => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<VoiceType[]>(REST_API.VOICE_DOWNLOAD.GET_VOICE_LIST);
    setVoiceList(data);
  };

  useEffect(() => {
    dispatch(getUserAuthThunk());
  }, []);

  useEffect(() => {
    getVoiceListApi().then();
  }, []);

  const handleClickPrev = () => {
    scroll.scrollMore(-58, {
      duration: 50,
      delay: 0,
      containerId: TABLE_ID,
    });
  };

  const handleClickNext = () => {
    scroll.scrollMore(58, {
      duration: 50,
      delay: 0,
      containerId: TABLE_ID,
    });
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <div className={classes.contents}>
          <Grid container justify="space-between" className={classes.descriptionContent}>
            <Grid item className={classes.description}>
              ・使用したいボイスをダウンロードし、USBメモリにコピーしてください。
              <br />
              ・ボイスを使用したい筐体を、「対象機器選択」で選んでください。
            </Grid>
          </Grid>
          <Grid className={classes.previousBtn}>
            {voiceList.length > 0 && <PrevNextButton isUp className="prevScroll" onClick={handleClickPrev} />}
          </Grid>
          <VoiceListTable voiceList={voiceList} tableId={TABLE_ID} />
          <Grid className={classes.nextBtn}>
            {MAX_DISPLAY_BAR <= voiceList.length && <PrevNextButton className="nextScroll" onClick={handleClickNext} />}
          </Grid>
        </div>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default VoiceList;
